export default {
    "1C Enterprise": {
        "color": "#814CCC",
    },
    "4D": {
        "color": "#004289",
    },
    "ABAP": {
        "color": "#E8274B",
    },
    "ABAP CDS": {
        "color": "#555e25",
    },
    "ActionScript": {
        "color": "#882B0F",
    },
    "Ada": {
        "color": "#02f88c",
    },
    "Adobe Font Metrics": {
        "color": "#fa0f00",
    },
    "Agda": {
        "color": "#315665",
    },
    "AGS Script": {
        "color": "#B9D9FF",
    },
    "AIDL": {
        "color": "#34EB6B",
    },
    "AL": {
        "color": "#3AA2B5",
    },
    "Alloy": {
        "color": "#64C800",
    },
    "Alpine Abuild": {
        "color": "#0D597F",
    },
    "Altium Designer": {
        "color": "#A89663",
    },
    "AMPL": {
        "color": "#E6EFBB",
    },
    "AngelScript": {
        "color": "#C7D7DC",
    },
    "Ant Build System": {
        "color": "#A9157E",
    },
    "ANTLR": {
        "color": "#9DC3FF",
    },
    "ApacheConf": {
        "color": "#d12127",
    },
    "Apex": {
        "color": "#1797c0",
    },
    "API Blueprint": {
        "color": "#2ACCA8",
    },
    "APL": {
        "color": "#5A8164",
    },
    "Apollo Guidance Computer": {
        "color": "#0B3D91",
    },
    "AppleScript": {
        "color": "#101F1F",
    },
    "Arc": {
        "color": "#aa2afe",
    },
    "AsciiDoc": {
        "color": "#73a0c5",
    },
    "ASL": {
        "color": null,
    },
    "ASP.NET": {
        "color": "#9400ff",
    },
    "AspectJ": {
        "color": "#a957b0",
    },
    "Assembly": {
        "color": "#6E4C13",
    },
    "Astro": {
        "color": "#ff5a03",
    },
    "Asymptote": {
        "color": "#ff0000",
    },
    "ATS": {
        "color": "#1ac620",
    },
    "Augeas": {
        "color": "#9CC134",
    },
    "AutoHotkey": {
        "color": "#6594b9",
    },
    "AutoIt": {
        "color": "#1C3552",
    },
    "Avro IDL": {
        "color": "#0040FF",
    },
    "Awk": {
        "color": "#c30e9b",
    },
    "Ballerina": {
        "color": "#FF5000",
    },
    "BASIC": {
        "color": "#ff0000",
    },
    "Batchfile": {
        "color": "#C1F12E",
    },
    "Beef": {
        "color": "#a52f4e",
    },
    "Befunge": {
        "color": null,
    },
    "BibTeX": {
        "color": "#778899",
    },
    "Bicep": {
        "color": "#519aba",
    },
    "Bison": {
        "color": "#6A463F",
    },
    "BitBake": {
        "color": "#00bce4",
    },
    "Blade": {
        "color": "#f7523f",
    },
    "BlitzBasic": {
        "color": "#00FFAE",
    },
    "BlitzMax": {
        "color": "#cd6400",
    },
    "Bluespec": {
        "color": "#12223c",
    },
    "Boo": {
        "color": "#d4bec1",
    },
    "Boogie": {
        "color": "#c80fa0",
    },
    "Brainfuck": {
        "color": "#2F2530",
    },
    "Brightscript": {
        "color": "#662D91",
    },
    "Browserslist": {
        "color": "#ffd539",
    },
    "C": {
        "color": "#555555",
    },
    "C#": {
        "color": "#178600",
    },
    "C++": {
        "color": "#f34b7d",
    },
    "C2hs Haskell": {
        "color": null,
    },
    "Cabal Config": {
        "color": "#483465",
    },
    "Cap'n Proto": {
        "color": "#c42727",
    },
    "CartoCSS": {
        "color": null,
    },
    "Ceylon": {
        "color": "#dfa535",
    },
    "Chapel": {
        "color": "#8dc63f",
    },
    "Charity": {
        "color": null,
    },
    "ChucK": {
        "color": "#3f8000",
    },
    "Cirru": {
        "color": "#ccccff",
    },
    "Clarion": {
        "color": "#db901e",
    },
    "Classic ASP": {
        "color": "#6a40fd",
    },
    "Clean": {
        "color": "#3F85AF",
    },
    "Click": {
        "color": "#E4E6F3",
    },
    "CLIPS": {
        "color": "#00A300",
    },
    "Clojure": {
        "color": "#db5855",
    },
    "Closure Templates": {
        "color": "#0d948f",
    },
    "Cloud Firestore Security Rules": {
        "color": "#FFA000",
    },
    "CMake": {
        "color": "#DA3434",
    },
    "COBOL": {
        "color": null,
    },
    "CodeQL": {
        "color": "#140f46",
    },
    "CoffeeScript": {
        "color": "#244776",
    },
    "ColdFusion": {
        "color": "#ed2cd6",
    },
    "ColdFusion CFC": {
        "color": "#ed2cd6",
    },
    "COLLADA": {
        "color": "#F1A42B",
    },
    "Common Lisp": {
        "color": "#3fb68b",
    },
    "Common Workflow Language": {
        "color": "#B5314C",
    },
    "Component Pascal": {
        "color": "#B0CE4E",
    },
    "Cool": {
        "color": null,
    },
    "Coq": {
        "color": "#d0b68c",
    },
    "Crystal": {
        "color": "#000100",
    },
    "CSON": {
        "color": "#244776",
    },
    "Csound": {
        "color": "#1a1a1a",
    },
    "Csound Document": {
        "color": "#1a1a1a",
    },
    "Csound Score": {
        "color": "#1a1a1a",
    },
    "CSS": {
        "color": "#563d7c",
    },
    "CSV": {
        "color": "#237346",
    },
    "Cuda": {
        "color": "#3A4E3A",
    },
    "CUE": {
        "color": "#5886E1",
    },
    "CWeb": {
        "color": "#00007a",
    },
    "Cycript": {
        "color": null,
    },
    "Cython": {
        "color": "#fedf5b",
    },
    "D": {
        "color": "#ba595e",
    },
    "Dafny": {
        "color": "#FFEC25",
    },
    "Darcs Patch": {
        "color": "#8eff23",
    },
    "Dart": {
        "color": "#00B4AB",
    },
    "DataWeave": {
        "color": "#003a52",
    },
    "Dhall": {
        "color": "#dfafff",
    },
    "DIGITAL Command Language": {
        "color": null,
    },
    "DirectX 3D File": {
        "color": "#aace60",
    },
    "DM": {
        "color": "#447265",
    },
    "Dockerfile": {
        "color": "#384d54",
    },
    "Dogescript": {
        "color": "#cca760",
    },
    "DTrace": {
        "color": null,
    },
    "Dylan": {
        "color": "#6c616e",
    },
    "E": {
        "color": "#ccce35",
    },
    "Earthly": {
        "color": "#2af0ff",
    },
    "Easybuild": {
        "color": "#069406",
    },
    "eC": {
        "color": "#913960",
    },
    "Ecere Projects": {
        "color": "#913960",
    },
    "ECL": {
        "color": "#8a1267",
    },
    "ECLiPSe": {
        "color": "#001d9d",
    },
    "EditorConfig": {
        "color": "#fff1f2",
    },
    "Eiffel": {
        "color": "#4d6977",
    },
    "EJS": {
        "color": "#a91e50",
    },
    "Elixir": {
        "color": "#6e4a7e",
    },
    "Elm": {
        "color": "#60B5CC",
    },
    "Emacs Lisp": {
        "color": "#c065db",
    },
    "EmberScript": {
        "color": "#FFF4F3",
    },
    "EQ": {
        "color": "#a78649",
    },
    "Erlang": {
        "color": "#B83998",
    },
    "F#": {
        "color": "#b845fc",
    },
    "F*": {
        "color": "#572e30",
    },
    "Factor": {
        "color": "#636746",
    },
    "Fancy": {
        "color": "#7b9db4",
    },
    "Fantom": {
        "color": "#14253c",
    },
    "Faust": {
        "color": "#c37240",
    },
    "Fennel": {
        "color": "#fff3d7",
    },
    "FIGlet Font": {
        "color": "#FFDDBB",
    },
    "Filebench WML": {
        "color": "#F6B900",
    },
    "Filterscript": {
        "color": null,
    },
    "fish": {
        "color": "#4aae47",
    },
    "Fluent": {
        "color": "#ffcc33",
    },
    "FLUX": {
        "color": "#88ccff",
    },
    "Forth": {
        "color": "#341708",
    },
    "Fortran": {
        "color": "#4d41b1",
    },
    "Fortran Free Form": {
        "color": "#4d41b1",
    },
    "FreeBasic": {
        "color": "#867db1",
    },
    "FreeMarker": {
        "color": "#0050b2",
    },
    "Frege": {
        "color": "#00cafe",
    },
    "Futhark": {
        "color": "#5f021f",
    },
    "G-code": {
        "color": "#D08CF2",
    },
    "Game Maker Language": {
        "color": "#71b417",
    },
    "GAML": {
        "color": "#FFC766",
    },
    "GAMS": {
        "color": "#f49a22",
    },
    "GAP": {
        "color": "#0000cc",
    },
    "GCC Machine Description": {
        "color": "#FFCFAB",
    },
    "GDB": {
        "color": null,
    },
    "GDScript": {
        "color": "#355570",
    },
    "GEDCOM": {
        "color": "#003058",
    },
    "Gemfile.lock": {
        "color": "#701516",
    },
    "Genie": {
        "color": "#fb855d",
    },
    "Genshi": {
        "color": "#951531",
    },
    "Gentoo Ebuild": {
        "color": "#9400ff",
    },
    "Gentoo Eclass": {
        "color": "#9400ff",
    },
    "Gerber Image": {
        "color": "#d20b00",
    },
    "Gherkin": {
        "color": "#5B2063",
    },
    "Git Attributes": {
        "color": "#F44D27",
    },
    "Git Config": {
        "color": "#F44D27",
    },
    "GLSL": {
        "color": "#5686a5",
    },
    "Glyph": {
        "color": "#c1ac7f",
    },
    "Gnuplot": {
        "color": "#f0a9f0",
    },
    "Go": {
        "color": "#00ADD8",
    },
    "Go Checksums": {
        "color": "#00ADD8",
    },
    "Go Module": {
        "color": "#00ADD8",
    },
    "Golo": {
        "color": "#88562A",
    },
    "Gosu": {
        "color": "#82937f",
    },
    "Grace": {
        "color": "#615f8b",
    },
    "Gradle": {
        "color": "#02303a",
    },
    "Grammatical Framework": {
        "color": "#ff0000",
    },
    "GraphQL": {
        "color": "#e10098",
    },
    "Graphviz (DOT)": {
        "color": "#2596be",
    },
    "Groovy": {
        "color": "#4298b8",
    },
    "Groovy Server Pages": {
        "color": "#4298b8",
    },
    "Hack": {
        "color": "#878787",
    },
    "Haml": {
        "color": "#ece2a9",
    },
    "Handlebars": {
        "color": "#f7931e",
    },
    "HAProxy": {
        "color": "#106da9",
    },
    "Harbour": {
        "color": "#0e60e3",
    },
    "Haskell": {
        "color": "#5e5086",
    },
    "Haxe": {
        "color": "#df7900",
    },
    "HCL": {
        "color": null,
    },
    "HiveQL": {
        "color": "#dce200",
    },
    "HLSL": {
        "color": "#aace60",
    },
    "HolyC": {
        "color": "#ffefaf",
    },
    "HTML": {
        "color": "#e34c26",
    },
    "HTML+ECR": {
        "color": "#2e1052",
    },
    "HTML+EEX": {
        "color": "#6e4a7e",
    },
    "HTML+ERB": {
        "color": "#701516",
    },
    "HTML+PHP": {
        "color": "#4f5d95",
    },
    "HTML+Razor": {
        "color": "#512be4",
    },
    "HTTP": {
        "color": "#005C9C",
    },
    "HXML": {
        "color": "#f68712",
    },
    "Hy": {
        "color": "#7790B2",
    },
    "HyPhy": {
        "color": null,
    },
    "IDL": {
        "color": "#a3522f",
    },
    "Idris": {
        "color": "#b30000",
    },
    "Ignore List": {
        "color": "#000000",
    },
    "IGOR Pro": {
        "color": "#0000cc",
    },
    "ImageJ Macro": {
        "color": "#99AAFF",
    },
    "Inform 7": {
        "color": null,
    },
    "INI": {
        "color": "#d1dbe0",
    },
    "Inno Setup": {
        "color": "#264b99",
    },
    "Io": {
        "color": "#a9188d",
    },
    "Ioke": {
        "color": "#078193",
    },
    "Isabelle": {
        "color": "#FEFE00",
    },
    "Isabelle ROOT": {
        "color": "#FEFE00",
    },
    "J": {
        "color": "#9EEDFF",
    },
    "JAR Manifest": {
        "color": "#b07219",
    },
    "Jasmin": {
        "color": "#d03600",
    },
    "Java": {
        "color": "#b07219",
    },
    "Java Properties": {
        "color": "#2A6277",
    },
    "Java Server Pages": {
        "color": "#2A6277",
    },
    "JavaScript": {
        "color": "#f1e05a",
    },
    "JavaScript+ERB": {
        "color": "#f1e05a",
    },
    "Jest Snapshot": {
        "color": "#15c213",
    },
    "JFlex": {
        "color": "#DBCA00",
    },
    "Jinja": {
        "color": "#a52a22",
    },
    "Jison": {
        "color": "#56b3cb",
    },
    "Jison Lex": {
        "color": "#56b3cb",
    },
    "Jolie": {
        "color": "#843179",
    },
    "jq": {
        "color": "#c7254e",
    },
    "JSON": {
        "color": "#292929",
    },
    "JSON with Comments": {
        "color": "#292929",
    },
    "JSON5": {
        "color": "#267CB9",
    },
    "JSONiq": {
        "color": "#40d47e",
    },
    "JSONLD": {
        "color": "#0c479c",
    },
    "Jsonnet": {
        "color": "#0064bd",
    },
    "Julia": {
        "color": "#a270ba",
    },
    "Jupyter Notebook": {
        "color": "#DA5B0B",
    },
    "Kaitai Struct": {
        "color": "#773b37",
    },
    "KakouneScript": {
        "color": "#6f8042",
    },
    "KiCad Layout": {
        "color": "#2f4aab",
    },
    "KiCad Legacy Layout": {
        "color": "#2f4aab",
    },
    "KiCad Schematic": {
        "color": "#2f4aab",
    },
    "Kotlin": {
        "color": "#A97BFF",
    },
    "KRL": {
        "color": "#28430A",
    },
    "LabVIEW": {
        "color": "#fede06",
    },
    "Lark": {
        "color": "#2980B9",
    },
    "Lasso": {
        "color": "#999999",
    },
    "Latte": {
        "color": "#f2a542",
    },
    "Lean": {
        "color": null,
    },
    "Less": {
        "color": "#1d365d",
    },
    "Lex": {
        "color": "#DBCA00",
    },
    "LFE": {
        "color": "#4C3023",
    },
    "LilyPond": {
        "color": "#9ccc7c",
    },
    "Limbo": {
        "color": null,
    },
    "Liquid": {
        "color": "#67b8de",
    },
    "Literate Agda": {
        "color": "#315665",
    },
    "Literate CoffeeScript": {
        "color": "#244776",
    },
    "Literate Haskell": {
        "color": "#5e5086",
    },
    "LiveScript": {
        "color": "#499886",
    },
    "LLVM": {
        "color": "#185619",
    },
    "Logos": {
        "color": null,
    },
    "Logtalk": {
        "color": "#295b9a",
    },
    "LOLCODE": {
        "color": "#cc9900",
    },
    "LookML": {
        "color": "#652B81",
    },
    "LoomScript": {
        "color": null,
    },
    "LSL": {
        "color": "#3d9970",
    },
    "Lua": {
        "color": "#000080",
    },
    "M": {
        "color": null,
    },
    "M4": {
        "color": null,
    },
    "M4Sugar": {
        "color": null,
    },
    "Macaulay2": {
        "color": "#d8ffff",
    },
    "Makefile": {
        "color": "#427819",
    },
    "Mako": {
        "color": "#7e858d",
    },
    "Markdown": {
        "color": "#083fa1",
    },
    "Marko": {
        "color": "#42bff2",
    },
    "Mask": {
        "color": "#f97732",
    },
    "Mathematica": {
        "color": "#dd1100",
    },
    "MATLAB": {
        "color": "#e16737",
    },
    "Max": {
        "color": "#c4a79c",
    },
    "MAXScript": {
        "color": "#00a6a6",
    },
    "mcfunction": {
        "color": "#E22837",
    },
    "Mercury": {
        "color": "#ff2b2b",
    },
    "Meson": {
        "color": "#007800",
    },
    "Metal": {
        "color": "#8f14e9",
    },
    "MiniD": {
        "color": null,
    },
    "Mirah": {
        "color": "#c7a938",
    },
    "mIRC Script": {
        "color": "#3d57c3",
    },
    "MLIR": {
        "color": "#5EC8DB",
    },
    "Modelica": {
        "color": "#de1d31",
    },
    "Modula-2": {
        "color": "#10253f",
    },
    "Modula-3": {
        "color": "#223388",
    },
    "Module Management System": {
        "color": null,
    },
    "Monkey": {
        "color": null,
    },
    "Moocode": {
        "color": null,
    },
    "MoonScript": {
        "color": "#ff4585",
    },
    "Motoko": {
        "color": "#fbb03b",
    },
    "Motorola 68K Assembly": {
        "color": "#005daa",
    },
    "MQL4": {
        "color": "#62A8D6",
    },
    "MQL5": {
        "color": "#4A76B8",
    },
    "MTML": {
        "color": "#b7e1f4",
    },
    "MUF": {
        "color": null,
    },
    "mupad": {
        "color": "#244963",
    },
    "Mustache": {
        "color": "#724b3b",
    },
    "Myghty": {
        "color": null,
    },
    "nanorc": {
        "color": "#2d004d",
    },
    "NASL": {
        "color": null,
    },
    "NCL": {
        "color": "#28431f",
    },
    "Nearley": {
        "color": "#990000",
    },
    "Nemerle": {
        "color": "#3d3c6e",
    },
    "nesC": {
        "color": "#94B0C7",
    },
    "NetLinx": {
        "color": "#0aa0ff",
    },
    "NetLinx+ERB": {
        "color": "#747faa",
    },
    "NetLogo": {
        "color": "#ff6375",
    },
    "NewLisp": {
        "color": "#87AED7",
    },
    "Nextflow": {
        "color": "#3ac486",
    },
    "Nginx": {
        "color": "#009639",
    },
    "Nim": {
        "color": "#ffc200",
    },
    "Nit": {
        "color": "#009917",
    },
    "Nix": {
        "color": "#7e7eff",
    },
    "NPM Config": {
        "color": "#cb3837",
    },
    "NSIS": {
        "color": null,
    },
    "Nu": {
        "color": "#c9df40",
    },
    "NumPy": {
        "color": "#9C8AF9",
    },
    "Nunjucks": {
        "color": "#3d8137",
    },
    "NWScript": {
        "color": "#111522",
    },
    "Objective-C": {
        "color": "#438eff",
    },
    "Objective-C++": {
        "color": "#6866fb",
    },
    "Objective-J": {
        "color": "#ff0c5a",
    },
    "ObjectScript": {
        "color": "#424893",
    },
    "OCaml": {
        "color": "#3be133",
    },
    "Odin": {
        "color": "#60AFFE",
    },
    "Omgrofl": {
        "color": "#cabbff",
    },
    "ooc": {
        "color": "#b0b77e",
    },
    "Opa": {
        "color": null,
    },
    "Opal": {
        "color": "#f7ede0",
    },
    "Open Policy Agent": {
        "color": "#7d9199",
    },
    "OpenCL": {
        "color": "#ed2e2d",
    },
    "OpenEdge ABL": {
        "color": "#5ce600",
    },
    "OpenQASM": {
        "color": "#AA70FF",
    },
    "OpenRC runscript": {
        "color": null,
    },
    "OpenSCAD": {
        "color": "#e5cd45",
    },
    "Org": {
        "color": "#77aa99",
    },
    "Ox": {
        "color": null,
    },
    "Oxygene": {
        "color": "#cdd0e3",
    },
    "Oz": {
        "color": "#fab738",
    },
    "P4": {
        "color": "#7055b5",
    },
    "Pan": {
        "color": "#cc0000",
    },
    "Papyrus": {
        "color": "#6600cc",
    },
    "Parrot": {
        "color": "#f3ca0a",
    },
    "Parrot Assembly": {
        "color": null,
    },
    "Parrot Internal Representation": {
        "color": null,
    },
    "Pascal": {
        "color": "#E3F171",
    },
    "Pawn": {
        "color": "#dbb284",
    },
    "PEG.js": {
        "color": "#234d6b",
    },
    "Pep8": {
        "color": "#C76F5B",
    },
    "Perl": {
        "color": "#0298c3",
    },
    "PHP": {
        "color": "#4F5D95",
    },
    "PicoLisp": {
        "color": "#6067af",
    },
    "PigLatin": {
        "color": "#fcd7de",
    },
    "Pike": {
        "color": "#005390",
    },
    "PLpgSQL": {
        "color": "#336790",
    },
    "PLSQL": {
        "color": "#dad8d8",
    },
    "PogoScript": {
        "color": "#d80074",
    },
    "Pony": {
        "color": null,
    },
    "PostCSS": {
        "color": "#dc3a0c",
    },
    "PostScript": {
        "color": "#da291c",
    },
    "POV-Ray SDL": {
        "color": "#6bac65",
    },
    "PowerBuilder": {
        "color": "#8f0f8d",
    },
    "PowerShell": {
        "color": "#012456",
    },
    "Prisma": {
        "color": "#0c344b",
    },
    "Processing": {
        "color": "#0096D8",
    },
    "Prolog": {
        "color": "#74283c",
    },
    "Promela": {
        "color": "#de0000",
    },
    "Propeller Spin": {
        "color": "#7fa2a7",
    },
    "Pug": {
        "color": "#a86454",
    },
    "Puppet": {
        "color": "#302B6D",
    },
    "PureBasic": {
        "color": "#5a6986",
    },
    "PureScript": {
        "color": "#1D222D",
    },
    "Python": {
        "color": "#3572A5",
    },
    "Python console": {
        "color": "#3572A5",
    },
    "Python traceback": {
        "color": "#3572A5",
    },
    "q": {
        "color": "#0040cd",
    },
    "Q#": {
        "color": "#fed659",
    },
    "QMake": {
        "color": null,
    },
    "QML": {
        "color": "#44a51c",
    },
    "Qt Script": {
        "color": "#00b841",
    },
    "Quake": {
        "color": "#882233",
    },
    "R": {
        "color": "#198CE7",
    },
    "Racket": {
        "color": "#3c5caa",
    },
    "Ragel": {
        "color": "#9d5200",
    },
    "Raku": {
        "color": "#0000fb",
    },
    "RAML": {
        "color": "#77d9fb",
    },
    "Rascal": {
        "color": "#fffaa0",
    },
    "RDoc": {
        "color": "#701516",
    },
    "REALbasic": {
        "color": null,
    },
    "Reason": {
        "color": "#ff5847",
    },
    "Rebol": {
        "color": "#358a5b",
    },
    "Record Jar": {
        "color": "#0673ba",
    },
    "Red": {
        "color": "#f50000",
    },
    "Redcode": {
        "color": null,
    },
    "Regular Expression": {
        "color": "#009a00",
    },
    "Ren'Py": {
        "color": "#ff7f7f",
    },
    "RenderScript": {
        "color": null,
    },
    "ReScript": {
        "color": "#ed5051",
    },
    "reStructuredText": {
        "color": "#141414",
    },
    "REXX": {
        "color": "#d90e09",
    },
    "Ring": {
        "color": "#2D54CB",
    },
    "Riot": {
        "color": "#A71E49",
    },
    "RMarkdown": {
        "color": "#198ce7",
    },
    "RobotFramework": {
        "color": "#00c0b5",
    },
    "Roff": {
        "color": "#ecdebe",
    },
    "Roff Manpage": {
        "color": "#ecdebe",
    },
    "Rouge": {
        "color": "#cc0088",
    },
    "RPC": {
        "color": null,
    },
    "Ruby": {
        "color": "#701516",
    },
    "RUNOFF": {
        "color": "#665a4e",
    },
    "Rust": {
        "color": "#dea584",
    },
    "Sage": {
        "color": null,
    },
    "SaltStack": {
        "color": "#646464",
    },
    "SAS": {
        "color": "#B34936",
    },
    "Sass": {
        "color": "#a53b70",
    },
    "Scala": {
        "color": "#c22d40",
    },
    "Scaml": {
        "color": "#bd181a",
    },
    "Scheme": {
        "color": "#1e4aec",
    },
    "Scilab": {
        "color": "#ca0f21",
    },
    "SCSS": {
        "color": "#c6538c",
    },
    "sed": {
        "color": "#64b970",
    },
    "Self": {
        "color": "#0579aa",
    },
    "ShaderLab": {
        "color": "#222c37",
    },
    "Shell": {
        "color": "#89e051",
    },
    "ShellCheck Config": {
        "color": "#cecfcb",
    },
    "ShellSession": {
        "color": null,
    },
    "Shen": {
        "color": "#120F14",
    },
    "Sieve": {
        "color": null,
    },
    "Singularity": {
        "color": "#64E6AD",
    },
    "Slash": {
        "color": "#007eff",
    },
    "Slice": {
        "color": "#003fa2",
    },
    "Slim": {
        "color": "#2b2b2b",
    },
    "Smali": {
        "color": null,
    },
    "Smalltalk": {
        "color": "#596706",
    },
    "Smarty": {
        "color": "#f0c040",
    },
    "SmPL": {
        "color": "#c94949",
    },
    "SMT": {
        "color": null,
    },
    "Solidity": {
        "color": "#AA6746",
    },
    "SourcePawn": {
        "color": "#f69e1d",
    },
    "SPARQL": {
        "color": "#0C4597",
    },
    "SQF": {
        "color": "#3F3F3F",
    },
    "SQL": {
        "color": "#e38c00",
    },
    "SQLPL": {
        "color": "#e38c00",
    },
    "Squirrel": {
        "color": "#800000",
    },
    "SRecode Template": {
        "color": "#348a34",
    },
    "Stan": {
        "color": "#b2011d",
    },
    "Standard ML": {
        "color": "#dc566d",
    },
    "Starlark": {
        "color": "#76d275",
    },
    "Stata": {
        "color": "#1a5f91",
    },
    "StringTemplate": {
        "color": "#3fb34f",
    },
    "Stylus": {
        "color": "#ff6347",
    },
    "SubRip Text": {
        "color": "#9e0101",
    },
    "SugarSS": {
        "color": "#2fcc9f",
    },
    "SuperCollider": {
        "color": "#46390b",
    },
    "Svelte": {
        "color": "#ff3e00",
    },
    "SVG": {
        "color": "#ff9900",
    },
    "Swift": {
        "color": "#F05138",
    },
    "SWIG": {
        "color": null,
    },
    "SystemVerilog": {
        "color": "#DAE1C2",
    },
    "Tcl": {
        "color": "#e4cc98",
    },
    "Tcsh": {
        "color": null,
    },
    "Terra": {
        "color": "#00004c",
    },
    "TeX": {
        "color": "#3D6117",
    },
    "Textile": {
        "color": "#ffe7ac",
    },
    "TextMate Properties": {
        "color": "#df66e4",
    },
    "Thrift": {
        "color": "#D12127",
    },
    "TI Program": {
        "color": "#A0AA87",
    },
    "TLA": {
        "color": "#4b0079",
    },
    "TOML": {
        "color": "#9c4221",
    },
    "TSQL": {
        "color": "#e38c00",
    },
    "TSV": {
        "color": "#237346",
    },
    "TSX": {
        "color": "#2b7489",
    },
    "Turing": {
        "color": "#cf142b",
    },
    "Twig": {
        "color": "#c1d026",
    },
    "TXL": {
        "color": "#0178b8",
    },
    "TypeScript": {
        "color": "#2b7489",
    },
    "Unified Parallel C": {
        "color": "#4e3617",
    },
    "Unity3D Asset": {
        "color": "#222c37",
    },
    "Unix Assembly": {
        "color": null,
    },
    "Uno": {
        "color": "#9933cc",
    },
    "UnrealScript": {
        "color": "#a54c4d",
    },
    "UrWeb": {
        "color": "#ccccee",
    },
    "V": {
        "color": "#4f87c4",
    },
    "Vala": {
        "color": "#fbe5cd",
    },
    "Valve Data Format": {
        "color": "#f26025",
    },
    "VBA": {
        "color": "#867db1",
    },
    "VBScript": {
        "color": "#15dcdc",
    },
    "VCL": {
        "color": "#148AA8",
    },
    "Verilog": {
        "color": "#b2b7f8",
    },
    "VHDL": {
        "color": "#adb2cb",
    },
    "Vim Help File": {
        "color": "#199f4b",
    },
    "Vim Script": {
        "color": "#199f4b",
    },
    "Vim Snippet": {
        "color": "#199f4b",
    },
    "Visual Basic .NET": {
        "color": "#945db7",
    },
    "Volt": {
        "color": "#1F1F1F",
    },
    "Vue": {
        "color": "#41b883",
    },
    "wdl": {
        "color": "#42f1f4",
    },
    "Web Ontology Language": {
        "color": "#5b70bd",
    },
    "WebAssembly": {
        "color": "#04133b",
    },
    "WebIDL": {
        "color": null,
    },
    "Wikitext": {
        "color": "#fc5757",
    },
    "Windows Registry Entries": {
        "color": "#52d5ff",
    },
    "wisp": {
        "color": "#7582D1",
    },
    "Wollok": {
        "color": "#a23738",
    },
    "World of Warcraft Addon Data": {
        "color": "#f7e43f",
    },
    "X10": {
        "color": "#4B6BEF",
    },
    "xBase": {
        "color": "#403a40",
    },
    "XC": {
        "color": "#99DA07",
    },
    "XML": {
        "color": "#0060ac",
    },
    "XML Property List": {
        "color": "#0060ac",
    },
    "Xojo": {
        "color": "#81bd41",
    },
    "Xonsh": {
        "color": "#285EEF",
    },
    "XProc": {
        "color": null,
    },
    "XQuery": {
        "color": "#5232e7",
    },
    "XS": {
        "color": null,
    },
    "XSLT": {
        "color": "#EB8CEB",
    },
    "Xtend": {
        "color": "#24255d",
    },
    "Yacc": {
        "color": "#4B6C4B",
    },
    "YAML": {
        "color": "#cb171e",
    },
    "YARA": {
        "color": "#220000",
    },
    "YASnippet": {
        "color": "#32AB90",
    },
    "ZAP": {
        "color": "#0d665e",
    },
    "Zeek": {
        "color": null,
    },
    "ZenScript": {
        "color": "#00BCD1",
    },
    "Zephir": {
        "color": "#118f9e",
    },
    "Zig": {
        "color": "#ec915c",
    },
    "ZIL": {
        "color": "#dc75e5",
    },
    "Zimpl": {
        "color": "#d67711",
    }
}